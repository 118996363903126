/* global $ */
/* eslint no-bitwise: 0 */

const generateUniqueId = () =>
  [...Array(10)].map(() => (~~(Math.random() * 36)).toString(36)).join('');

// using inline SVGs so we can control the titles/classes
const makeButton = ({
  direction = 'prev',
  title = 'Previous',
  viewBox = '0 0 21.8 40',
  d = '',
}) => {
  const uniqueId = generateUniqueId();
  return `<button class="slick-${direction} slick-arrow" aria-label="Slideshow ${title} Button" type="button" role="button"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="${viewBox}" aria-labelledby="title-${uniqueId}" xml:space="preserve"><title id="title-${uniqueId}">${title}</title><path fill="currentColor" d="${d}"/></svg></button>`;
};

$.$body.find('.image-slider').slick({
  // https://github.com/kenwheeler/slick/#settings
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  draggable: true,
  // adaptiveHeight: true,
  accessibility: true,
  // focusOnChange: true,
  // ^ prefer using a custom afterChange callback to focus that takes sticky header into account
  prevArrow: makeButton({
    d: 'M0 20L20 0l1.8 1.8L3.6 20l18.2 18.2L20 40 0 20z',
  }),
  nextArrow: makeButton({
    direction: 'next',
    title: 'Next',
    d: 'M21.802 20L1.808 40 0 38.192 18.192 20 0 1.808 1.808 0l19.994 20z',
  }),
});
