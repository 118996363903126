/* global $ */

import { throttleDomAfterAsync } from '../helpers/throttle';

const scrollEffectDelay = 70; // delay any scroll events by this many MS
const $dottedNav = $.$body.find('.dotted-nav');

if ($dottedNav.length) {
  // this seems like a lot of work to change one thing white on scroll
  const checkWhiteClass = throttleDomAfterAsync(() => {
    const windowSize = $(window).height() / 2;
    const scrollPosition = $(window).scrollTop();
    if (scrollPosition >= windowSize) {
      $dottedNav.removeClass('white');
    } else {
      $dottedNav.addClass('white');
    }
  }, scrollEffectDelay);

  $(window).on('scroll', checkWhiteClass);

  // activate dotted nav on load
  const s = $.$body.find('.module.activeSection').attr('id');
  $dottedNav.find(`#anchor-${s}`).addClass('active');

  // event to check which section is active
  const toggleNavColorScroll = throttleDomAfterAsync(() => {
    setTimeout(() => {
      const _s = $.$body.find('.module.activeSection').attr('id');
      $dottedNav.find('a.active').removeClass('active');
      $dottedNav.find(`#anchor-${_s}`).addClass('active');
    }, 200);
  }, scrollEffectDelay);

  $(window).on('scroll', toggleNavColorScroll);
}
