/* global $ */

import { throttleDomAfterAsync } from '../helpers/throttle';

const scrollEffectDelay = 70; // delay any scroll events by this many MS
const $socialNav = $.$body.find('.social-nav');

$.$body.on('click', '.social .social-pop', function(e) {
  if ($(window).width() > 967) {
    e.preventDefault();
    window.open(
      this.href,
      '',
      'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=350,width=600',
    );
  }
});

if ($socialNav.length > -1) {
  const checkOverlap = throttleDomAfterAsync(() => {
    const stickyShare = $socialNav.overlaps('.social-hide');
    $socialNav[stickyShare.hits.length ? 'addClass' : 'removeClass']('behind');
    $socialNav[stickyShare.hits.length ? 'addClass' : 'removeClass']('animate');
  }, scrollEffectDelay + 10);

  $(window).on('scroll resize', checkOverlap);
  checkOverlap();
}
