/* global $, Cookies */

$.$body.find('.video-popup').magnificPopup({
  type: 'iframe',
  mainClass: 'mfp-fade',
  removalDelay: 160,
  preloader: false,
  fixedContentPos: false,
});

$.$body.find('.popup-youtube').magnificPopup({
  disableOn: 700,
  type: 'iframe',
  mainClass: 'mfp-fade',
  removalDelay: 160,
  preloader: false,
  fixedContentPos: false,
});

const $popOver = $.$body.find('.pop-over');

// Modal Popups. Global/page using Magnific Popup plugin.
//----------------------------------------------------------------------------
if ($popOver.length > 0) {
  /**
   * Depending on the type of modal used (if any), initializes it
   * accordingly.
   *
   * @return {void}
   */
  const initializeModal = () => {
    // console.log('show Modalal');
    $popOver.find('[data-src]').each((i, el) => {
      const $img = $(el);
      $img.attr('src', $img.data('src'));
    });
    $popOver.addClass('is-visible');
    if ($popOver.hasClass('image')) {
      // Image modal.
      const $imagePopup = $.$body.find('#image-popup');
      if ($imagePopup.length > 0) {
        $imagePopup.magnificPopup({
          type: 'image',
          closeOnContentClick: true,
          mainClass: 'mfp-img-mobile',
          image: { verticalFit: true },
          callbacks: {
            open: () => {
              $('body').removeClass('is-splashed');
            },
          },
        });
        $imagePopup.click();
      } else {
        $.$body.find('#image-popup-linkable').magnificPopup({
          type: 'inline',
          preloader: false,
          callbacks: {
            open: () => {
              $('body').removeClass('is-splashed');
            },
          },
        });
        $.$body.find('#image-popup-linkable').click();
      }
    } else if ($popOver.hasClass('video')) {
      // console.log('video modal');
      const isAutoPlay = $('.pop-over.video .play-row').data('autoplay');
      // Video modal.
      $.$body.removeClass('is-splashed');
      if (!isAutoPlay) {
        $popOver.on('click', () => {
          // console.log('clicked .pop-over');
          $('.pop-over.video').fadeOut();
        });
      }
      // $popOver.find('.video-play-bg').on('click touchstart', e => {
      //   console.log('clicked .video-play-bg');
      //   e.preventDefault();
      //   e.stopPropagation();
      //   e.stopImmediatePropagation();
      //   // $popOver.find('[data-fancybox]').click();
      // });
      $popOver.find('[data-fancybox]').on('touchstart', (e) => {
        // console.log('clicked fancy');
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
        $popOver.find('[data-fancybox]').click();
      });
      if (isAutoPlay === true) {
        $popOver.find('[data-fancybox]').click();
      }
    } else if ($popOver.hasClass('html')) {
      // HTML modal.
      const $inlinePopupLink = $.$body.find('#inline-popup-link');
      $inlinePopupLink.magnificPopup({
        type: 'inline',
        preloader: false,
        callbacks: {
          open: () => {
            $.$body.removeClass('is-splashed');
          },
        },
      });
      $inlinePopupLink.click();
    } else if ($popOver.hasClass('bsd')) {
      // BSD modal.
      const $bsdPopupLink = $.$body.find$('#bsd-popup-link');
      $bsdPopupLink.magnificPopup({
        type: 'inline',
        preloader: false,
        callbacks: {
          open: () => {
            $.$body.removeClass('is-splashed');
          },
        },
      });
      $bsdPopupLink.click();
    }
  };

  // Show or don't show modal based on cookie logic.
  const $popupOptions = $.$body.find('.js-popup-options');
  const frequency = $popupOptions.data('frequency');
  const cookieName = $popupOptions.data('cookie-name');
  if (!Cookies.getJSON(cookieName) || frequency === 'always') {
    initializeModal();
    if (frequency !== 'always') {
      if (frequency === 'session') {
        Cookies.set(cookieName, 'shown');
      } else {
        const cookieLengths = { week: 7, once: 999 };
        Cookies.set(cookieName, 'shown', {
          expires: cookieLengths[frequency],
        });
      }
    }
  }
}
