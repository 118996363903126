/* eslint no-unused-vars: 0  */
/* global $ */

// const animationBreakpoint = 960; // see matching breakpoint in global/effects/animations.scss
const hasIOSupport =
  'IntersectionObserver' in window &&
  'IntersectionObserverEntry' in window &&
  'intersectionRatio' in window.IntersectionObserverEntry.prototype;

// polyfill for browsers that do not support IOs
const nodes = [];

// addClassOnScrollTo ::  DOMNode -> null
const addClassOnScrollTo = (className) => (node, threshold = 0) => {
  const { height, top } = node.getBoundingClientRect();
  const { scrollY, innerHeight, pageYOffset } = window;
  const scrollPos = typeof scrollY !== 'undefined' ? scrollY : pageYOffset;

  const topEdge = scrollPos + top + height * threshold;

  nodes.push({
    topEdge,
    height,
    node,
    className,
    threshold,
  });
};

const standardDelay = 0.25;

// this adds staggered animation delays to each matching child element
// so that they can animate in on demand
$.$body.find('[data-animate-children]').each((i, el) => {
  const $el = $(el);
  const childSelector = $(el).data('animate-children');
  const delay = $(el).data('animate-children-delay');

  $el
    .find(childSelector)
    .addClass('js-animated-child')
    .each((index, child) => {
      $(child).css({
        animationDelay: Number(delay || standardDelay) * (0 + index) + 's',
      });
    });
});

// moves data-src/sizes/scrset to native attributes
const lazyLoadFromDataAttr = (el) => {
  const data = $(el).data();
  const { src, srcset, sizes } = data;
  // console.log({ data });
  if (src) {
    el.src = src;
  }
  if (srcset) {
    el.srcset = srcset;
  }
  if (sizes) {
    el.sizes = sizes;
  }
};

// intersection observer-based section classes to trigger animations
const addClass = (className) => (entries, observer) => {
  if (entries.length && entries[0].intersectionRatio <= 0) {
    return;
  }
  entries.forEach((entry) => {
    const $target = $(entry.target).removeClass('activeSection');
    if (entry.isIntersecting) {
      $target
        .addClass(`${className} activeSection`)
        .find('[data-src], [data-srcset]')
        .each((i, el) => lazyLoadFromDataAttr(el));
      observer.disconnect();
    }
  });
};

const addClassAtThreshold = (node, className = '', threshold = 0.15) => {
  if (hasIOSupport) {
    const observer = new IntersectionObserver(addClass(className), {
      rootMargin: '0px',
      threshold: [threshold, 1 - threshold], // fire on top and bottom edges
    });
    observer.observe(node);
  } else {
    // alternatively, we could add these to a polyfill...
    // but the browser share of desktop safari and oldIE is pretty tiny....
    // $(node).addClass(className);
    addClassOnScrollTo(className)(node, threshold);
  }
};

// the percentage visible a module must be to load
const defaultModuleThreshold = 0.2;

$.$body.find('[data-module]').each((i, thisModule) => {
  const maxThreshold = window.innerHeight / thisModule.offsetHeight;
  let threshold =
    $(thisModule).data('module-seen-threshold') || defaultModuleThreshold;
  if (threshold > maxThreshold) {
    threshold = maxThreshold;
  }
  addClassAtThreshold(thisModule, 'seenSection', threshold);
});

// the percentage at which a series animation must be visible to start loading
const defaultAnimateChildThreshold = 0.1;

$.$body.find('[data-animate-children]').each((i, thisModule) => {
  const maxThreshold = window.innerHeight / thisModule.offsetHeight;
  let threshold =
    $(thisModule).data('module-seen-threshold') || defaultAnimateChildThreshold;
  if (threshold > maxThreshold) {
    threshold = maxThreshold;
  }
  addClassAtThreshold(thisModule, 'animate-children', threshold);
});

// $('[data-src]').each((i, thisModule) => {
//   addClassAtThreshold(thisModule, 'animate-children', 0.1);
// });

// for browsers that do not support IOs, we'll simply keep track of their
// relative scroll heights and activate them purely based on scrolling
if (!hasIOSupport && nodes.length) {
  const $window = $(window);

  const scrollHandler = (e) => {
    const { scrollY, innerHeight, pageYOffset } = window;
    const scrollPos = typeof scrollY !== 'undefined' ? scrollY : pageYOffset;

    nodes.forEach((nodeWatcher) => {
      const { node, className, topEdge, bottomEdge, height } = nodeWatcher;
      const $node = $(node).removeClass('activeSection');
      if (topEdge < scrollPos + innerHeight && topEdge + height > scrollPos) {
        $node
          .addClass(`${className} activeSection`)
          .find('[data-src], [data-srcset]')
          .each((i, el) => lazyLoadFromDataAttr(el));
        nodes.splice(nodes.indexOf(nodeWatcher), 1);
      }
    });
    if (!nodes.length) {
      $window.off('scroll', scrollHandler);
    }
  };

  // recreate nodeList with new heights
  $window.on('resize', (e) => {
    nodes.forEach((nodeWatcher) => {
      const { node, className, threshold } = nodeWatcher;
      nodes.splice(nodes.indexOf(nodeWatcher), 1); // remove from the array
      addClassOnScrollTo(className)(node, threshold);
    });
  });
  $window.on('scroll', scrollHandler).scroll();
  setTimeout(() => $window.scroll(), 1000);
}
