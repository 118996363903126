/* global $ */

import { gup } from '../helpers/params';

// NGP
const $ngpCallback = $.$body.find('.ngp-code.add-callback');
if ($ngpCallback.length > -1) {
  const ngpCallback = () => {
    $ngpCallback.each(function() {
      $(this)
        .find('label.EmailAddress')
        .addClass('show');
      $(this)
        .find('label.EmailAddress input')
        .attr('placeholder', 'Your Email');

      $(this)
        .find('label.PostalCode')
        .addClass('show');
      $(this)
        .find('label.PostalCode input')
        .attr('placeholder', 'Zip Code');
    });
  };

  // strange & worrisome that this is necessary
  $(window).bind('load', () => {
    setTimeout(ngpCallback, 500);
    setTimeout(ngpCallback, 1000);
    setTimeout(ngpCallback, 2000);
  });
}

// BSD sourcing
const source = gup('utm_source') || gup('source');

$(document).ready(() => {
  if (source) {
    $.$body.find('.bsd-form').each(function() {
      const $form = $(this);
      if ($form.find('[name="source"]').length) {
        $form.find('[name="source"]').val(source);
      } else {
        $('<input/>', {
          type: 'hidden',
          name: 'source',
          value: source,
        }).appendTo($form);
      }
    });
  }
});
